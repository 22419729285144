@import '~antd/dist/antd.less';
@import './theme.less';

.row{
  &:not(:first-child){
    margin-top: 20px;
  }
  &:not(:last-child){
    margin-bottom: 20px;
  }
}
:global {
  .ant-input-group-wrapper {
    width: 100%;
  }
  .ant-form-item-control {
    line-height: initial;
  }
}