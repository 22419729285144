.form{
  width: 400px;
  margin: 0 auto;
  position: relative;
  top: 100px;
  .forgotButton{
    float: right;
  }
  .loginButton{
    width: 100%;
  }
}