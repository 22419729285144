
.form{
  width: 360px;
  height: 360px;
  margin: 10px auto 0;
  overflow: hidden;
  .forgotButton{
    float: right;
    color: rgba(24, 144, 255, 1);
    font-size: 14px;
  }
  .loginButton{
    width: 100%;
    line-height: 32px;
  }
}
.info {
  text-align: center;
  color: #bbbbbb;
  padding: 0 0 10px 0;
}